import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Login from './content/Login';
import PrivateRoute from './PrivateRoute'; // Importar o PrivateRoute

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Applications

const UsersList = Loader(
  lazy(() => import('src/content/applications/UsersList'))
);
const WebhooksList = Loader(
  lazy(() => import('src/content/applications/WebhooksList'))
);
const TransactionsList = Loader(
  lazy(() => import('src/content/applications/TransactionsList'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);
const UserCompany = Loader(
  lazy(() => import('src/content/applications/UsersList/UserCompany'))
);
const UserCommunity = Loader(
  lazy(() => import('src/content/applications/UsersList/UserCommunity'))
);
const UserCompanyForm = Loader(
  lazy(() => import('src/content/applications/UsersList/UserCompanyForm'))
);
const UserCommunityForm = Loader(
  lazy(() => import('src/content/applications/UsersList/UserCommunityForm'))
);
// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="users" replace />
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: <PrivateRoute element={<UsersList />} />
          },
          {
            path: ':userId/companies',
            element: <PrivateRoute element={<UserCompany />} />
          },
          {
            path: ':userId/companies/create',
            element: <PrivateRoute element={<UserCompanyForm />} />
          },
          {
            path: ':userId/companies/update',
            element: <PrivateRoute element={<UserCompanyForm />} />
          },
          {
            path: ':userId/communities',
            element: <PrivateRoute element={<UserCommunity />} />
          },
          {
            path: ':userId/community/create',
            element: <PrivateRoute element={<UserCommunityForm />} />
          },
          {
            path: ':userId/community/update',
            element: <PrivateRoute element={<UserCommunityForm />} />
          },
        ]
      },
      {
        path: 'transactions',
        element: <PrivateRoute element={<TransactionsList />} />
      },
      {
        path: 'webhooks',
        element: <PrivateRoute element={<WebhooksList />} />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <PrivateRoute element={<UserProfile />} />
          },
          {
            path: 'settings',
            element: <PrivateRoute element={<UserSettings />} />
          }
        ]
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  }
];

export default routes;
