import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  () => `
    display: flex;
    text-decoration: none;
    align-items: center;
`
);

const LogoImage = styled('img')(
  () => `
    width: 160px;
    height: auto;
`
);

function Logo() {
  return (
    <LogoWrapper to="/">
      <LogoImage src="/static/images/logo/wespline-logo.svg" alt="Logo" />
    </LogoWrapper>
  );
}

export default Logo;
