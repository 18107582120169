import Axios from 'axios'
import { authService } from './authService'

export const crmApi = Axios.create({
    baseURL: process.env.REACT_APP_CRM_API_BASE_URL
})
crmApi.interceptors.request.use(config => {
    const token = authService.getToken()
    if(token && !authService.isAuthenticated()) {
        authService.logout()
        window.location.href = '/'
    }
    config.headers['authorization'] = `Bearer ${token}`
    return config
})