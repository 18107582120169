import { jwtDecode } from "jwt-decode";
const tokenName = 'accessToken'
function getToken() {
    return localStorage.getItem(tokenName) || ''
}

function isAuthenticated(): boolean {
    const token = getToken()
    if (!token) return false
    try {
        const decoded = jwtDecode(token)
        if (new Date(decoded.exp*1000) <= new Date()) {
            return false
        }
        return true
    } catch {
        return false
    }
}

function getUserInfo() {
    const token = getToken()
    if (!token) return {}
    return jwtDecode(token)
}
function setToken(token: string) {
    localStorage.setItem(tokenName, token)
}
function logout() {
    localStorage.removeItem(tokenName)
}
export const authService = {
    getToken,
    setToken,
    isAuthenticated,
    getUserInfo,
    logout
}
