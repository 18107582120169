import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { authService } from './services/authService';

interface PrivateRouteProps extends RouteProps {
  element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, ...rest }) => {
  //const isAuthenticated = !!localStorage.getItem('authToken');
  const isAuthenticated = authService.isAuthenticated();

  return isAuthenticated ? <>{element}</> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
